<script lang="ts">
	let classNames: string = '';
	export let modalId: string;
	export let text: string;
	export let disabled: boolean = false;
	export { classNames as class };
</script>

<label for={modalId} class="modal-button {classNames}" disabled={disabled || null}>
	{text}
</label>
