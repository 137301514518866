<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import ModalToggler from './ModalToggler.svelte';

	const dispatch = createEventDispatcher();

	export let modalId: string;
	export let showCloseButton: boolean = true;
	export let closeIfClickOutside: boolean = false;
	export let widthFitContent: boolean = false;
	export let title: string = '';

	function getVisibilityCheckbox(): HTMLInputElement {
		return document.getElementById(modalId) as HTMLInputElement;
	}

	export function setVisibility(_visible: boolean) {
		getVisibilityCheckbox().checked = _visible;
	}
</script>

<input
	type="checkbox"
	id={modalId}
	class="modal-toggle"
	on:change={(event) => event.currentTarget.checked === false && dispatch('close')}
/>
<label for={closeIfClickOutside ? modalId : ''} class="modal">
	<label for="" class="modal-box relative" class:w-fit={widthFitContent}>
		{#if showCloseButton}
			<ModalToggler {modalId} class="btn btn-sm btn-circle absolute right-2 top-2" text="x" />
		{/if}
		{#if title}
			<h3 class="text-lg font-bold">{title}</h3>
		{/if}
		<slot />
	</label>
</label>
